html{
  font-size: 10px;
  /*background-color:#f4f7fc;*/
  /*--production_version: 2022.12.07*/
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a{
  text-decoration: none;
}

@media only screen and (max-width: 499px){
  .desktopOnly{
    display: none !important;
  }
}

@media only screen and (min-width: 500px){
  .mobileOnly{
    display: none !important;
  }
}

.swiper-slide > div > div{
  transition: .5s;
}

.swiper-slide-prev > div > div, .swiper-slide-next > div > div{
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.swiper-slide:not(.swiper-slide-active){
  height: 55vh;
}

.link-disabled{
  pointer-events: none;
}

.swiper-slide:empty{
  display: none;
}