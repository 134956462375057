
@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-SemiboldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge';
    src: url('./F37Judge-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge';
    src: url('./F37Judge-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Compressed';
    src: url('./F37Judge-RegularCompressed.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Compressed';
    src: url('./F37Judge-RegularCompressedItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Condensed';
    src: url('./F37Judge-RegularCondensed.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Condensed';
    src: url('./F37Judge-RegularCondensedItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Extended';
    src: url('./F37Judge-RegularExtended.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Extended';
    src: url('./F37Judge-RegularExtendedItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge';
    src: url('./F37Judge-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge';
    src: url('./F37Judge-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Thin Compressed';
    src: url('./F37Judge-ThinCompressedItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Thin Condensed';
    src: url('./F37Judge-ThinCondensed.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Thin Compressed';
    src: url('./F37Judge-ThinCompressed.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Thin Condensed';
    src: url('./F37Judge-ThinCondensedItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Thin Extended';
    src: url('./F37Judge-ThinExtended.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Bold Condensed';
    src: url('./F37Judge-BoldCondensed.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge';
    src: url('./F37Judge-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge';
    src: url('./F37Judge-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Medium Compressed';
    src: url('./F37Judge-MediumCompressed.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge';
    src: url('./F37Judge-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Bold Compressed';
    src: url('./F37Judge-BoldCompresseditalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Bold Condensed';
    src: url('./F37Judge-BoldCondensedItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Medium Compressed';
    src: url('./F37Judge-MediumCompressedItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Bold Extended';
    src: url('./F37Judge-BoldExtended.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Bold Compressed';
    src: url('./F37Judge-BoldCompressed.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Bold Extended';
    src: url('./F37Judge-BoldExtendedItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Medium Condensed';
    src: url('./F37Judge-MediumCondensedItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Medium Extended';
    src: url('./F37Judge-MediumExtended.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Medium Extended';
    src: url('./F37Judge-MediumExtendedItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'F37 Judge Medium Condensed';
    src: url('./F37Judge-MediumCondensed.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge Thin Extended';
    src: url('./F37Judge-ThinExtendedItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'F37 Judge';
    src: url('./F37Judge-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: "Hellix";
    src: url("./Hellix-Black.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-BlackItalic.woff2") format("woff2");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-BoldItalic.woff2") format("woff2");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-ExtraBold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-ExtraBoldItalic.woff2") format("woff2");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-LightItalic.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-MediumItalic.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Hellix";
    src: url("./Hellix-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-RegularItalic.woff2") format("woff2");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-SemiBoldItalic.woff2") format("woff2");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-Thin.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hellix";
    src: url("./Hellix-ThinItalic.woff2") format("woff2");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}